<footer class="footer ">
  <div class="container-fluid">
    <!-- <nav class="pull-left">
      <ul>
        <li>
          <a href="">
            Creative Tim
          </a>
        </li>
        <li>
          <a href="">
            About Us
          </a>
        </li>
        <li>
          <a href="">
            Blog
          </a>
        </li>
        <li>
          <a href="">
            Licenses
          </a>
        </li>
      </ul>
    </nav> -->
    <!-- <div class="copyright pull-right">
      &copy;
      {{test | date: 'yyyy'}}, {{'global.footer.made_with' | translate}}<i class="material-icons">favorite</i> {{'global.footer.by_creatice_tim' | translate}}
    </div> -->
    <div class="copyright">
      Copyright ©2023 All rights reserved, Upstart
    </div>
  </div>
</footer>
