<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand" href="{{getPath()}}"> {{getTitle() | translate}}</a>
    </div>
    <div class="navbar-btn-search">
      <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navigation">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link my_profile" [routerLink]="['/system/my-profile']">
              <i class="material-icons">person</i>
              <p>
                <span class="d-lg-none d-md-block">Account</span>
              </p>
            </a>
          </li>
          <app-notification></app-notification>
          <app-language [isDark]="true"></app-language>
        </ul>
      </div>
    </div>
  </div>
</nav>
