<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">assignment</i>
            </div>
            <h4 class="card-title">DataTables.net</h4>
          </div>
          <div class="card-body">
            <div class="toolbar">
              <!--        Here you can write extra buttons/actions for the toolbar              -->
            </div>
            <div class="material-datatables">
              <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0"
                width="100%" style="width:100%">
                <thead>
                  <tr>
                    <th>{{ dataTable.headerRow[0] }}</th>
                    <th>{{ dataTable.headerRow[1] }}</th>
                    <th>{{ dataTable.headerRow[2] }}</th>
                    <th>{{ dataTable.headerRow[3] }}</th>
                    <th>{{ dataTable.headerRow[4] }}</th>
                    <th class="disabled-sorting text-right">{{ dataTable.headerRow[5] }}</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>{{ dataTable.footerRow[0] }}</th>
                    <th>{{ dataTable.footerRow[1] }}</th>
                    <th>{{ dataTable.footerRow[2] }}</th>
                    <th>{{ dataTable.footerRow[3] }}</th>
                    <th>{{ dataTable.footerRow[4] }}</th>
                    <th class="text-right">{{ dataTable.footerRow[5] }}</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr *ngFor="let row of dataTable.dataRows">
                    <td>{{row[0]}}</td>
                    <td>{{row[1]}}</td>
                    <td>{{row[2]}}</td>
                    <td>{{row[3]}}</td>
                    <td>{{row[4]}}</td>
                    <td class="text-right">
                      <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons row_action">favorite</i></a>
                      <a class="btn btn-link btn-warning btn-just-icon edit"><i class="material-icons row_action">dvr</i></a>
                      <a class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons row_action">close</i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end content-->
        </div>
        <!--  end card  -->
      </div>
      <!-- end col-md-12 -->
    </div>
    <!-- end row -->
  </div>
</div>