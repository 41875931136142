<div class="logo">
  <a href="" class="simple-text logo-normal">
    <img src="assets/img/logo/new-upstart-logo.svg" />
  </a>
</div>

<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img class="image" alt="User" src="{{
        currentUser.avatar
          ? currentUser.avatar
          : 'assets/img/profile_dark.svg'
        }}"
      />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{currentUser.first_name}} {{currentUser.last_name}}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/system/my-profile']">
              <span class="sidebar-normal">{{'user.profile.my_profile' | translate}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="logout()">
              <span class="sidebar-normal logout">{{'user.profile.logout' | translate}}
                <span class="loader-block" *ngIf="isLoading"></span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Stats</span>
          </p>
        </a>
      </li>
      <app-notification></app-notification>
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Account</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>
          {{menuitem.translateKey | translate}}
          <span class="nav-link__count" *ngIf="menuitem.count !== undefined">
            {{menuitem.count}}
          </span>
        </p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.translateKey | translate}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse toggle_sections" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
